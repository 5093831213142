<template>
  <div style="padding: 16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入产品名称" v-model="queryInfo.condition.productName" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="产品编号" align="center" prop="productCode">
        </el-table-column>
        <el-table-column label="产品名称" align="center" prop="productName">
        </el-table-column>
        <el-table-column label="分类名称" align="center" prop="menuName">
        </el-table-column>
        <el-table-column label="标题" align="center" prop="title">
        </el-table-column>
        <el-table-column label="封面图片" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-image style="width: 80px; height: 80px;" :preview-src-list="scope.row.trainingsCover.split(',')"
              :src="scope.row.trainingsCover">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="排序" align="center" prop="seq">
        </el-table-column>
        <el-table-column label="价格" align="center" prop="trainingsPrice">
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime">
        </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row.id)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[10, 20, 30, 40]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="labelType == 'add' ? '新增' : '编辑'" :visible.sync="showDialog" width="80%"
      class="mainPublishDialog">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="产品名称：" prop="productName">
              <el-input v-model="form.productName" placeholder="请输入产品名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="menuCode">
              <el-cascader @change="changeProduct" v-model="form.menuCode" style="width:100%;" ref="productList"
                :options="productList"
                :props="{ expandTrigger: 'click', value: 'categoryCode', label: 'categoryName', children: 'children', checkStrictly: true }">
              </el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="标题：" prop="">
              <el-input v-model="form.title" placeholder="请输入标题名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序：" prop="">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="价格：" prop="">
              <el-input v-model="form.trainingsPrice" type="number" placeholder="请输入价格"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转产品编号：" prop="skipSystemId">
              <el-input v-model="form.skipSystemId" placeholder="请输入跳转产品编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="封面图片：" prop="trainingsCover">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-progress="() => { this.ICLoading = true }" :on-success="handleCoverSuccess"
                :before-upload="beforeAvatarUpload">
                <div v-if="imagesCoverShow" class="imgBox">
                  <el-image :src="form.trainingsCover" class="avatar" alt="" />
                  <div class="img-icon">
                    <i class="el-icon-zoom-in" @click="handleImageCoverPreview"></i>
                    <i class="el-icon-delete" @click="handleImageCoverRemove"></i>
                  </div>
                </div>
                <i v-else v-loading="ICLoading" class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="产品图片：" :rules="{ required: true, message: '请上传产品图片', trigger: 'blur,change' }">
              <el-upload class="avatar-uploader" :action="$uploadURL" :show-file-list="false"
                :on-progress="() => { this.IULoading = true }" :on-success="handleimageSuccess"
                :before-upload="beforeAvatarUpload" :multiple="true">
                <div v-for="(item, index) in detailList">
                  <div class="imgBox">
                    <el-image :src="item.trainingUrl" class="avatar" alt="" />
                    <div class="img-icon">
                      <i class="el-icon-zoom-in" @click="handleImageUrlPreview($event, index)"></i>
                      <i class="el-icon-delete" @click="handleImageRemove($event, index)"></i>
                    </div>
                  </div>
                  <div>{{ item.trainingName }}</div>
                </div>
                <i v-loading="IULoading" class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="homePageClose">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave('form')">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      labelType: "add",
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {
          trainingsType: "1"
        },
      },
      form: {
        productName: "", // 产品名称
        menuCode: "", // 分类编号
        menuName: "", // 分类名称
        title: "", // 标题
        seq: "", // 排序
        trainingsCover: "", // 封面图片
        trainingUrl: "",  //产品图片
        detailList: [], //产品图片
        trainingsPrice: '',   // 价格
        skipSystemId: '',//跳转名称
        menuCodeParent: "", //一级分类编号
      },
      rules: {
        productName: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
        menuCode: [{ required: true, message: '请选择分类名称', trigger: 'change' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        seq: [{ required: true, message: '请输入排序', trigger: 'blur' }],
        trainingsPrice: [{ required: true, message: '请输入价格', trigger: 'blur' }],
        trainingsCover: [{ required: true, message: '请上传封面图片', trigger: 'blur' }],
        trainingUrl: [{ required: true, message: '请上传产品图片', trigger: 'blur' }],
      },
      detailList: [],
      height: 0,
      showDialog: false,
      productList: [],
      IULoading: false,
      ICLoading: false,
      dialogVisible: false,
      dialogImageUrl: "",
      imagesCoverShow: false,
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener("resize", this.getHeight());
    });
  },

  methods: {
    //上传超出限制
    uploadOverLimit() {
      this.$notify.error({
        title: '提示',
        message: '一次最大上传数不能超过30张！',
        showClose: true
      })
    },

    // 获取图片分类
    getProduct() {
      this.$http.post('/trainingsCategory/queryTreeList', this.form)
        .then(res => {
          if (res.data.code == 200) {
            this.productList = this.getTreeList(res.data.data)
          } else {
            this.$notify.info({
              title: '提示',
              message: res.data.message,
              showClose: true
            })
          }
        })
    },

    getTreeList(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          data[i].children = undefined
        } else {
          this.getTreeList(data[i].children)
        }
      }
      return data
    },

    // 产品分类名称选择
    changeProduct(val) {
      if (val.length === 0) {
        this.form.menuCode = ''
        this.form.menuName = ''
      }
      let categoryData = this.$refs.productList.getCheckedNodes()
      if (categoryData.length <= 0) {
        this.form.menuCode = ''
        this.form.menuName = ''
      } else {
        this.form.menuCode = categoryData[categoryData.length - 1].data.categoryCode
        this.form.menuName = categoryData[categoryData.length - 1].data.categoryName
        this.form.menuCodeParent = categoryData[0].data.categoryCode
      }
    },

    // 监听表格高度
    getHeight() {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList() {
      this.$http
        .post("/trainings/list", this.queryInfo)
        .then(res => {
          if (res.data.code == 200) {
            this.total = res.data.data.count;
            this.queryInfo.currPage = res.data.data.currPage;
            this.tableData = res.data.data.data;
          } else {
            this.$notify.info({
              title: '提示',
              message: res.data.message,
              showClose: true
            })
          }
        });
    },

    // 保存
    handleSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // for (let i in this.detailList) {
          //   this.detailList[i].trainingName = this.form.title;
          // }
          this.form.detailList = this.detailList;
          this.form.trainingsName = this.form.title;

          this.$http.post('/trainings/saveTrainingCollection', this.form)
            .then(res => {
              if (res.data.code === 200) {
                this.$notify.success({
                  title: "提示",
                  message: "保存成功",
                  showClose: true,
                });
                this.showDialog = false;
                this.getList();
              } else {
                this.$notify.info({
                  title: "提示",
                  message: res.data.message,
                  showClose: true,
                });
              }
            })

          // if (this.form.id) {
          //
          // } else {
          //   this.$http
          //     .post("/images/saveImageCollection", data)
          //     .then(res => {
          //       if (res.data.code == 200) {
          //         this.$notify.success({
          //           title: "提示",
          //           message: "保存成功",
          //           showClose: true,
          //         });
          //         this.showDialog = false;
          //         this.getList();
          //       } else {
          //         this.$notify.info({
          //           title: "提示",
          //           message: res.data.message,
          //           showClose: true,
          //         });
          //       }
          //     })
          // }
        }
      })

    },

    // 关闭表单
    homePageClose() {
      this.showDialog = false;
    },

    //修改
    edit(id) {
      this.form.id = id
      this.showDialog = true;
      this.labelType = "edit";
      this.getProduct()

      this.$http.post('/trainings/queryOne', {
        id
      }).then(res => {
        if (res.data.code == 200) {
          this.form.productName = res.data.data.productName;
          this.form.menuCode = res.data.data.menuCode;
          this.form.menuName = res.data.data.menuName;
          this.form.title = res.data.data.title;
          this.form.seq = res.data.data.seq;
          this.form.trainingsPrice = res.data.data.trainingsPrice;
          this.form.trainingsCover = res.data.data.trainingsCover;
          this.detailList = res.data.data.detailList
          this.form.skipSystemId = res.data.data.skipSystemId
          if (this.form.trainingsCover) {
            this.imagesCoverShow = true
          }
        } else {
          this.$notify.info({
            title: '提示',
            message: res.data.message,
            showClose: true
          })
        }
      })
    },

    // 删除
    handleDelte(id) {
      this.$http
        .post("/trainings/delete", { id })
        .then(res => {
          if (res.data.code == 200) {
            this.$notify.success({
              title: "提示",
              message: "删除成功",
              showClose: true,
            });
            this.getList();
          } else {
            this.$notify.info({
              title: "提示",
              message: res.data.message,
              showClose: true,
            });
          }
        });
    },

    //新增按钮
    newBtn() {
      this.labelType = "add";
      this.showDialog = true;
      this.form = {};
      this.detailList = []
      this.imagesCoverShow = false;
      this.getProduct();
    },

    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },

    // 图片上传
    handleimageSuccess(val) {
      let data = {}
      data.trainingName = val.data.fileName
      data.trainingUrl = val.data.url
      this.detailList.push(data)
      // this.$set(this.form,'imageUrl',val.data.url)
      this.IULoading = false;
    },
    handleCoverSuccess(val) {
      this.$set(this.form, 'trainingsCover', val.data.url)
      this.ICLoading = false;
      this.imagesCoverShow = true;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    handleImageUrlPreview(e, index) {
      e.stopPropagation();
      this.dialogImageUrl = this.detailList[index].imageUrl;
      this.dialogVisible = true;
    },
    handleImageRemove(e, index) {
      console.log(index)
      e.stopPropagation();
      this.$nextTick(() => {
        this.detailList.splice(index, 1)
      })
    },
    handleImageCoverPreview(e) {
      e.stopPropagation();
      this.dialogImageUrl = this.form.imagesCover;
      this.dialogVisible = true;
    },
    handleImageCoverRemove(e) {
      e.stopPropagation();
      this.$nextTick(() => {
        this.form.imagesCover = "",
          this.imagesCoverShow = false
      })
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader {
  width: 100%;
}

.avatar-uploader ::v-deep .el-upload {
  //background-color: #fbfdff;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  border: 1px dashed #c0ccda;
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

/deep/.el-loading-spinner {
  margin-top: -74px
}

.imgBox {
  //width: 148px;
  height: 148px;
  position: relative;
  //margin-bottom: 5px;
}

.imgBox:hover .img-icon {
  display: flex;
}

.img-icon {
  width: 148px;
  height: 148px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.4);

  i {
    font-size: 26px;
    color: #fff;
  }
}

.avatar {
  display: block;
  width: 148px;
  height: 148px;
  border-radius: 6px;
}

.mainPublishDialog /deep/ .el-dialog__body {
  height: 70vh;
  overflow: scroll;
}

.mainPublishDialog /deep/ .el-dialog {
  margin-top: 7vh !important;
}

/deep/ .el-dialog__wrapper {
  overflow: hidden;
}

.tabsInput /deep/ .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 100% !important;
}

.tabsInput /deep/ .el-input__inner {
  width: 100% !important;
}

/deep/ .el-dialog {
  margin-top: 8vh !important;
}

/deep/ .el-dialog__body {
  max-height: 70vh !important;
  overflow-y: scroll !important;
}
</style>
